var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pane_body"},[_c('v-card',{attrs:{"flat":"","color":"rgb(255 255 245)"}},[_c('v-toolbar',{staticClass:"breads",attrs:{"flat":"","dense":""}},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumb},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true},{key:"item",fn:function({ item }){return [_c('v-breadcrumbs-item',{attrs:{"to":item.to,"disabled":item.disabled}},[_vm._v(" "+_vm._s(item.text.toUpperCase())+" ")])]}}])}),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","color":"success"},on:{"click":function($event){$event.stopPropagation();_vm.doctypeNewDialog = true}}},on),[_vm._v(" new ")])]}}])},[_c('span',[_vm._v("Create new Document Type")])]),_c('DocTypeNew',{attrs:{"doctypeNewDialog":_vm.doctypeNewDialog},on:{"closeNew":_vm.closeNew,"submitNew":_vm.submitNew}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":"","text":"","color":"info"},on:{"click":function($event){return _vm.lists()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh")])])],1)],1),_c('v-container',[_c('v-card',{attrs:{"color":"transparent","flat":""}},[_c('v-card',{attrs:{"flat":"","rounded":"0"}},[_c('v-card-text',[_c('v-data-table',{attrs:{"loader-height":"2","loading":_vm.loading,"headers":_vm.headers,"items":_vm.documentypeList,"items-per-page":8,"footer-props":{
              itemsPerPageOptions: [8, 16, 24],
              // showFirstLastPage: true,
              // firstIcon: 'mdi-arrow-collapse-left',
              // lastIcon: 'mdi-arrow-collapse-right',
              // prevIcon: 'mdi-minus',
              // nextIcon: 'mdi-plus'
            }},scopedSlots:_vm._u([{key:"item.created",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("myDating")(item.created))+" ")]}},{key:"item.actions",fn:function({ item }){return [_c('v-btn',{attrs:{"text":"","x-small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.$set(_vm.doctypeViewDialog, item.id, true)}}},[_vm._v(" view ")]),(_vm.doctypeViewDialog[item.id])?_c('DocTypeView',{attrs:{"myId":item.id,"name":item.name,"doctypeViewDialog":_vm.doctypeViewDialog[item.id]},on:{"closeView":_vm.closeView}}):_vm._e(),_c('v-btn',{attrs:{"text":"","x-small":"","color":"success"},on:{"click":function($event){$event.stopPropagation();return _vm.$set(_vm.doctypeEditDialog, item.id, true)}}},[_vm._v(" edit ")]),(_vm.doctypeEditDialog[item.id])?_c('DocTypeEdit',{attrs:{"myId":item.id,"name":item.name,"doctypeEditDialog":_vm.doctypeEditDialog[item.id]},on:{"closeEdit":_vm.closeEdit,"submitEdit":_vm.submitEdit}}):_vm._e(),_c('v-btn',{attrs:{"text":"","x-small":"","color":"pink"},on:{"click":function($event){$event.stopPropagation();return _vm.$set(_vm.doctypeRemoveDialog, item.id, true)}}},[_vm._v("DELETE ")]),(_vm.doctypeRemoveDialog[item.id])?_c('DocTypeRemove',{attrs:{"myId":item.id,"name":item.name,"doctypeRemoveDialog":_vm.doctypeRemoveDialog[item.id]},on:{"closeRemove":_vm.closeRemove,"submitRemove":_vm.submitRemove}}):_vm._e()]}}])})],1)],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snack.color,"multi-line":_vm.snack.multiLine},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snack.bar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snack.bar),callback:function ($$v) {_vm.$set(_vm.snack, "bar", $$v)},expression:"snack.bar"}},[_vm._v(" "+_vm._s(_vm.snack.text)+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }